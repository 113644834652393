import Debug from 'debug';

import hash from 'object-hash';

import { WebSocket } from 'partysocket';
import ShareDb from 'sharedb/lib/client';
import ShareDbJSProxy from 'sharedb-jsproxy';
import ShareDbPromises from 'sharedb-promises';

import React, { useContext, useState, useEffect } from 'react';

import {
	Box, 
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Icon,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	TextField,
	Tooltip
} from '@mui/material';

import {
	createTheme,
	styled,
	ThemeProvider
} from '@mui/material/styles';

import {
	Add as AddIcon,
	DeleteForever as DeleteForeverIcon
} from '@mui/icons-material';

const debugShareDb = Debug('sharedb:Console:ShareDb');
ShareDb.logger.setMethods({
	info: debugShareDb,
	warn: debugShareDb,
	error: debugShareDb
});

import { AppContext } from './AppContext';
import Loader from './Loader';
import Collections from './Collections';

export default function () {
	const {
		session, setSession,
		shareDbConnection, setShareDbConnection,
		tenantProxy, setTenantProxy
	} = useContext(AppContext);

	const debug = Debug('sharedb:Console:'+session.account.id);

	debug('Console');

	useEffect(() => {
		async function initShareDb() {
			const wsUrl = window.location.href.replace(/^http/,'ws')+'ws';
			const authProtocols = () => {
				const protocols = [];
				if(session.oidc.access_token) {
					protocols.push(btoa('Authorization: Bearer '+session.oidc.access_token));
				}
				return protocols;
			};
			const socket = new WebSocket( wsUrl, authProtocols );
	
			socket.addEventListener('error', e => {
				debug('WebSocket failed. Checking health.', e);
			});

			socket.addEventListener('close', e => {
				debug('WebSocket closed. Checking health.', e);
			});

			const _shareDbConnection = new ShareDb.Connection(socket);
			_shareDbConnection.debug = true;
			setShareDbConnection(_shareDbConnection);

			const tenantDoc = _shareDbConnection.get('tenant', session.account.id);
			const initData = {
				idps: [],
				collections: []
			};

			const tenantDocPromises = ShareDbPromises.doc(tenantDoc);
			await tenantDocPromises.fetchOrCreate(initData);
			await tenantDocPromises.subscribe();
			debug('tenant', tenantDoc);
			setTenantProxy(new ShareDbJSProxy(tenantDoc));
		}
		initShareDb();
	}, []);

	if(!tenantProxy) {
		return <Loader />;
	}

	return <Collections />;
}

