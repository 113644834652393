import Debug from 'debug';

import React, { useContext, useState, useEffect } from 'react';

import {
	Box, 
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Icon,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';

import {
	createTheme,
	styled,
	ThemeProvider
} from '@mui/material/styles';

import {
	Add as AddIcon,
	DeleteForever as DeleteForeverIcon
} from '@mui/icons-material';

import { AppContext } from './AppContext';
import Loader from './Loader';
import { state as _s } from './utils';
import Collection from './Collection';

export default function () {
	const {
		session, setSession,
		tenantProxy, setTenantProxy
	} = useContext(AppContext);

	const [ state, setState ] = useState({});

	const debug = Debug('sharedb:Collections:'+session.account.id);

	debug('Collections');

	const handleCreateCollection = async event => {
		debug('handleCreateCollection', event);
		const collections = (await tenantProxy.collections).slice();
		collections.push({ _id: crypto.randomUUID() });
		tenantProxy.collections = collections;
		await tenantProxy.collections;
	};

	const handleDeleteCollection = async (index, event) => {
		debug('handleDeleteCollection', index, event);
		const collections = (await tenantProxy.collections).slice();
		collections.splice(index, 1);
		tenantProxy.collections = collections;
		await tenantProxy.collections;
	};

	const setStateCollectionsFromShareDb = async () => {
		const collections = (await tenantProxy).collections;
		const change = {
			collections: collections.map(collection => ({ _id: collection.id }))
		};
		setState(_s(change)); 
	};

	const handleTenantChange = async event => {
		if(event.path?.[0] != 'collections') {
			debug('handleTenantChange', 'ignoring nested event', event);
			return;
		}

		setStateCollectionsFromShareDb();
	};

	if(!tenantProxy) {
		return <Loader />;
	}

	useEffect(() => {
		(async () => {
			tenantProxy.__proxy__.on('change', handleTenantChange);
			setStateCollectionsFromShareDb();
		})();
	}, []);

	if(!state.collections?.length) {
		return <Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
				You do not have any ShareDb collections configured yet.
			</Typography>
			<Button variant='outlined' onClick={handleCreateCollection}>Create a collection</Button>
		</Box>;
	}

	debug('render', state.collections);
	return <Box
		display='flex'
		justifyContent='center'
		alignItems='center'
		flexDirection='column'
	>
		<Tooltip placement='top-start' title='Add new collection'>
			<AddIcon onClick={handleCreateCollection} />
		</Tooltip>
		{state.collections.map((collection, i) => {
			return <Box key={i+':'+collection._id}>
				<Collection index={i} />
				<Tooltip placement='top-start' title='Delete this collection'>
					<DeleteForeverIcon onClick={(event) => handleDeleteCollection(i, event)} />
				</Tooltip>
			</Box>;
		})}
	</Box>;

}

