import Debug from 'debug';

import React, { useContext, useState, useEffect, useRef } from 'react';

import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Icon,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	TextField
} from '@mui/material';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { ArrowForwardOutlined as ArrowForwardOutlinedIcon } from '@mui/icons-material';

import { AppContext } from './AppContext';
import Loader from './Loader';

export default function (props) {
	const {
		session, setSession,
		tenantProxy, setTenantProxy
	} = useContext(AppContext);

	const {
		index
	} = props;

	const refs = {
		_id: useRef(),
		name: useRef(),
		ezsso_oidc_auth_id: useRef()
	};

	const [ collection, setCollection ] = useState(null);

	const selections = {};

	const debug = Debug('sharedb:Collection:'+session.account.id+':'+index);
	debug("Collection");

	useEffect(() => {
		(async () => {
			setCollection((await tenantProxy.collections)[index]);
		})();
	}, []);

	if(!collection) {
		return <Loader />;
	}

	const handleShareDbChange = async event => {
		debug('handleShareDbChange', 'from sharedb', event, refs);	
		const input = refs[event.prop]?.current;
		if(!input) {
			debug('handleShareDbChange', event, refs);	
			return;
		}

		if(event.promise instanceof Promise) {
			await event.promise;
		}

		input.value = event.data;
		restoreSelection(event.prop);
	};
	collection.__proxy__.on('change', handleShareDbChange);

	const restoreSelection = async prop => {
		const input = refs[prop]?.current;
		if(!input) {
			debug('restoreSelection', 'no input', prop, refs);
			return;
		}

		const selection = selections[prop];
		if(!selection) {
			debug('restoreSelection', 'no selection', prop, selections);
			return;
		}

		debug('restoreSelection', prop, selection);
/*
		input.selectionStart = selection.start;
		input.selectionEnd = selection.end;
*/
	};

	const handleSelectionChange = async (prop, event) => {
		const input = refs[prop]?.current;
		if(!input) {
			debug('handleSelectionChange', 'no input', prop, refs);	
			return;
		}

		const selection = selections[prop] = {
			start: input.selectionStart,
			end: input.selectionEnd
		};

		debug('handleSelectionChange', prop, selection, event);
	};

	const handleTextChange = async (prop, event) => {
		debug('handleTextChange', 'to sharedb', prop, event);
		collection[prop] = event.target.value;
		await collection[prop];
	};

	return <form><Box
		display="flex"
		justifyContent="center"
		alignItems="center"
		flexDirection="column"
	>
		<TextField
			inputRef={refs.name}
			id={'collection:'+index+':name'}
			label='Name'
			size='small'
			inputProps={{ style: { width: '350px', textAlign: 'center' } }}
			sx={{ mt: '16px', mr: '4px' }}
			defaultValue={ collection.name || ''}
			autoComplete='collection_name'
			onSelect={event => handleSelectionChange('name', event)}
			onChange={event => handleTextChange('name', event)}
		/>
		<TextField
			inputRef={refs.ezsso_oidc_auth_id}
			id={'collection:'+index+':ezsso_oidc_auth_id'}
			label='EZSSO Id'
			size='small'
			inputProps={{ style: { width: '350px', textAlign: 'center' } }}
			sx={{ mt: '16px', mr: '4px' }}
			defaultValue={ collection.ezsso_oidc_auth_id || ''}
			autoComplete='ezsso_oidc_auth_id'
			onSelect={event => handleSelectionChange('ezsso_oidc_auth_id', event)}
			onChange={event => handleTextChange('ezsso_oidc_auth_id', event)}
		/>
		<TextField
			inputRef={refs._id}
			id={'collection:'+index+':_id'}
			label='Id'
			size='small'
			inputProps={{ style: { width: '350px', textAlign: 'center' } }}
			sx={{ mt: '16px', mr: '4px' }}
			defaultValue={ collection._id || ''}
			autoComplete='collection__id'
			onSelect={event => handleSelectionChange('_id', event)}
			onChange={event => handleTextChange('_id', event)}
		/>
	</Box></form>;

}

