import Debug from 'debug';

import { refetch } from './utils';

const debug = Debug('sharedb:api');

const logout = async () => {
	debug('logout >');
	return refetch(`/api/logout`, { credentials: 'include' })
	.then(response => response.json())
	.then(data => {
		debug('logout <', data);
	})
	.catch(e => {
		debug('logout !', e);
	});
};

module.exports = {
	logout
};
