import Debug from 'debug';
import React, { useState, useContext, useEffect } from 'react';

import { Icon } from '@mui/material';

import { AppContext } from './AppContext';
import AppBar from './AppBar';
import Welcome from './Welcome';
import Console from './Console';
import Loader from './Loader';
import LoggedOut from './LoggedOut';

const debug = Debug('sharedb:App');

const appContext = {};

export default function () {
	const [ error, setError ] = useState(null);
	const [ session, setSession ] = useState({ login: localStorage.login == 'true'});
	const [ shareDbConnection, setShareDbConnection ] = useState(null);
	const [ tenantProxy, setTenantProxy ] = useState(null);

	Object.assign(appContext, {
		error, setError,
		session, setSession,
		shareDbConnection, setShareDbConnection,
		tenantProxy, setTenantProxy
	});

	useEffect(() => {
		debug('useEffect session.login', session.login);
		localStorage.login = session.login;
	}, [session.login]);

	function getAppComponent() {
		if(!session.login) {
			return <Welcome />;
		}

		if(!session.loaded) {
			return <Loader />;
		}

		if(session.logged_out) {
			return <LoggedOut />;
		}

		if(session.account) {
			return <Console />;
		}

		return <div>Error</div>;
	}

	return (
		<AppContext.Provider value={ appContext }>
			<AppBar />
			{getAppComponent()}
		</AppContext.Provider>
	);
}

