import Debug from 'debug';
import React, { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";

import { Box, Button, Grid, FormControl, FormControlLabel, FormLabel, Icon, Paper, Radio, RadioGroup, Switch, Typography } from '@mui/material';

import { AppContext } from './AppContext';

const debug = Debug('sharedb:Welcome');

export default function () {
	debug("Welcome");

	const {
		session, setSession
	} = useContext(AppContext);

	const handleLoginClick = () => setSession(Object.assign({}, session, {login: true}));

	const markdown = `
[ShareDB](https://github.com/share/sharedb) as a Service.  
Sign in.  
Setup your collection.  
And off you go!  
We run it. You use it.  
Simple as that.  
`;

	return (
		<>
			<ReactMarkdown linkTarget="_blank" children={markdown} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className="Welcome" />
			<br/>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
			>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					<Button variant="outlined" onClick={handleLoginClick}>Try it.</Button>
				</Typography>
			</Box>
		</>
	);
}

